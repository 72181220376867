import React, { lazy, useState } from "react";

import { Box, Button, Paper, TextField } from "@mui/material";
import { FiPlus } from "react-icons/fi";
import { AddCustomer, CatchError } from "../../../setup/services";
import Swal from "sweetalert2";
import Title from "components/Title";
import useAuth from "hooks/useAuth";
import ButtonLoading from "components/ButtonLoading";
import { useTranslation } from "react-i18next";
const CustomBox = lazy(() => import("components/CustomBox"));

const Ajouter = ({ isDialog, setIsInsert }) => {
  const [t, i18n] = useTranslation("global");
  const { auth } = useAuth();
  const [loading, setLoaded] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const { name, address, phone, email } = formData;
  const SendValue = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const SendForm = (e) => {
    e.preventDefault();
    setLoaded(true);
    setFormErrors({
      name: "",
      email: "",
      phone: "",
    });
    AddCustomer(name, address, phone, email, auth.branch)
      .then((response) => {
        if (response.status === 201) {
          MessageValidate();
          if (isDialog) setIsInsert((prev) => !prev);
          setFormData({
            name: "",
            phone: "",
            email: "",
            address: "",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.errors?.length > 0) {
          error.response.data.errors.forEach((objet) => {
            if (formErrors.hasOwnProperty(objet.path)) {
              setFormErrors((prev) => ({
                ...prev,
                [objet.path]: objet.msg,
              }));
            }
          });
        } else {
          CatchError();
        }
      })
      .finally(() => {
        setLoaded(false);
      });
  };
  const MessageValidate = () => {
    Swal.fire({
      title: "Valider!",
      text: t("Le client a été bien ajouté"),
      icon: "success",
      showConfirmButton: true,
      confirmButtonColor: "#2399baff",
      timer: 2500,
    });
  };
  return (
    <Box width={"75%"} marginX={"auto"}>
      <Title title={t("Ajouter un client")} />
      {!isDialog && (
        <CustomBox
          title={t("Ajouter un client")}
          parent={t("Clients")}
          url={"clients"}
        />
      )}

      <Box component={Paper} elevation={3} padding={2} borderRadius={2}>
        <form action="" className="form" onSubmit={(e) => SendForm(e)}>
          <TextField
            focused
            error={formErrors.name === "" ? false : true}
            helperText={formErrors.name === "" ? false : formErrors.name}
            id="name"
            label={t("Client")}
            className="label_txtField"
            variant="outlined"
            onChange={(e) => SendValue(e)}
            value={name}
          />
          <TextField
            error={formErrors.phone === "" ? false : true}
            helperText={formErrors.phone === "" ? false : formErrors.phone}
            id="phone"
            label={t("Téléphone")}
            variant="outlined"
            value={phone}
            className="label_txtField"
            onChange={(e) => SendValue(e)}
          />
          <TextField
            error={formErrors.email === "" ? false : true}
            helperText={formErrors.email === "" ? false : formErrors.email}
            id="email"
            value={email}
            label={`${t("Email")}/ ${t("Téléphone")} 2`}
            className="label_txtField"
            onChange={(e) => SendValue(e)}
            variant="outlined"
          />

          <TextField
            id="address"
            className="label_txtField"
            label={t("Adresse")}
            variant="outlined"
            onChange={(e) => SendValue(e)}
          />
          <div className="btn-add_produit">
            {loading ? (
              <ButtonLoading />
            ) : (
              <Button
                variant="contained"
                type="submit"
                style={{ marginLeft: 8 }}
                startIcon={<FiPlus />}
              >
                {t("Ajouter")}
              </Button>
            )}
          </div>
        </form>
      </Box>
    </Box>
  );
};

export default Ajouter;
